/**
  WARNING!!! This file should only be altered for boilerplate.
  Changes will be propagated to all sites.
**/

import * as React from "react";
import Layout from "../components/Layout";
import UserProfile from "../components/organisms/UserProfile";
import { Helmet } from "react-helmet";
import Container from "@mui/material/Container";
const pageName = "What are NFTs?";

const Register = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <meta
          name="title"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta
          name="description"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />

        {/* OpenGraph tags */}
        <meta
          property="og:title"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta
          property="og:description"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://artefy-strapi-boilerplate-4mdevelopment.s3.ap-southeast-2.amazonaws.com/metal_plus_97826b79e2.png"
        />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="50" />

        {/* Twitter Card tags */}
        <meta
          name="twitter:card"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta
          name="twitter:creator"
          content={`${process.env.GATSBY_TWITTER_HANDLE}`}
        />
        <meta
          name="twitter:title"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta
          name="twitter:description"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <title>{`${process.env.GATSBY_SITE_NAME} | ${pageName}`}</title>
      </Helmet>

      <Container maxWidth="md" className="py-20">
        <UserProfile></UserProfile>
      </Container>
    </Layout>
  );
};

export default Register;

import * as React from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import mmIcon from "../../../src/assets/images/defaultUserPic_1.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import appConstants from "../../constants/appConstants";
import axios from "axios";
import { useSnackbar } from "notistack";
import { UPDATE_USER_AVATAR, UPDATE_USER_DETAILS } from "../../redux/types";
import AppContext from "../../context/AppContext";
import TwoColorHeader from "../atoms/TwoColorHeader";
import CloseIcon from "@mui/icons-material/Close";
var FormData = require("form-data");

const UserProfile = ({ data }) => {
  const userLoginState = useSelector((state) => state.isLogged);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const userNameInput = useRef();
  const emailInput = useRef();
  const send_on_latest_blog_articles = useRef();
  const send_on_general_marketing_emails = useRef();
  const send_on_new_drops = useRef();

  const [img, setImg] = React.useState(
    userLoginState.avatar.url
      ? userLoginState?.avatar?.url?.startsWith("http")
        ? userLoginState.avatar.url
        : process.env.GATSBY_SHARED_STRAPI_ASSET_URL + userLoginState.avatar.url
      : null
  );
  const handleImage = async (event) => {
    const fileUploaded = event.target.files[0];
    const objectUrl = URL.createObjectURL(fileUploaded);
    setImg(objectUrl);
    await uploadAvatar(fileUploaded);
  };
  const { editProfileState, updateEditProfileState } =
    React.useContext(AppContext);
  React.useEffect(() => {
    return () => {
      updateEditProfileState(false);
    };
  }, [updateEditProfileState]);
  const closeNotification = (snackbarId) => (
    <button onClick={() => closeSnackbar(snackbarId)}>
      <CloseIcon />
    </button>
  );
  const uploadAvatar = async (fileUploaded) => {
    try {
      var data = new FormData();
      data.append("files", fileUploaded);
      data.append("ref", "plugin::users-permissions.user");
      data.append("refId", userLoginState.user.id);
      data.append("field", "avatar");
      data.append("source", "users-permissions");
      let url = `${process.env.GATSBY_SHARED_STRAPI_BASE_URL}/api/upload`;
      if (userLoginState.avatar.id)
        url = `${url}?id=${userLoginState.avatar.id}`;

      const newAvatar = await axios.post(url, data, {
        headers: { Authorization: `Bearer ${userLoginState.jwt}` },
      });
      enqueueSnackbar("Avatar updated.", {
        variant: "success",
        action: closeNotification,
      });
      let resAvatar = newAvatar.data;
      if (Array.isArray(newAvatar.data)) {
        resAvatar = newAvatar.data[0];
      }
      dispatch({
        type: UPDATE_USER_AVATAR,
        avatar: { ...resAvatar, url: resAvatar.url + "?ref=" + Date.now() },
      });
    } catch (err) {
      enqueueSnackbar("Unable to update user Avatar.", {
        variant: "error",
        action: closeNotification,
      });
    }
  };
  React.useEffect(() => {
    setImg(
      userLoginState.avatar.url
        ? userLoginState?.avatar?.url?.startsWith("http")
          ? userLoginState.avatar.url
          : process.env.GATSBY_SHARED_STRAPI_ASSET_URL +
            userLoginState.avatar.url
        : null
    );
  }, [userLoginState]);

  const createOrUpdateUserNotification = async () => {
    try {
      const notification = {
        data: {
          latest_blog_articles: send_on_latest_blog_articles.current.checked,
          general_marketing_emails:
            send_on_general_marketing_emails.current.checked,
          new_drops: send_on_new_drops.current.checked,
          users_permissions_user: userLoginState.user.id,
        },
      };
      // Create or Update notification Setting.
      if (userLoginState.user.notification) {
        // Update notification.
        const userNotification = await axios.put(
          `${process.env.GATSBY_SHARED_STRAPI_BASE_URL}/api/notifications/${userLoginState.user.notification.id}`,
          notification,
          { headers: { Authorization: `Bearer ${userLoginState.jwt}` } }
        );
        notification.data.id = userLoginState.user.notification.id;
      } else {
        // Create notification entry.
        const userNotification = await axios.post(
          `${process.env.GATSBY_SHARED_STRAPI_BASE_URL}/api/notifications`,
          notification,
          { headers: { Authorization: `Bearer ${userLoginState.jwt}` } }
        );
        notification.data.id = userNotification.data.data.id;
      }
      return notification;
    } catch (e) {
      console.log("Error: While updateing notification settings: " + e);
    }
  };

  const updateProfile = async () => {
    let isError = false;
    const res = validateEmail(emailInput.current.value);
    if (!res) {
      emailInput.current.style.borderColor = "red";
      isError = true;
    }
    if (userNameInput.current.value.trim() === "") {
      userNameInput.current.style.borderColor = "red";
      isError = true;
    }
    if (isError) return;
    try {
      const notification = await createOrUpdateUserNotification();
      const data = {
        artefyUserName: userNameInput.current.value,
        email: emailInput.current.value,
      };
      const newUser = await axios.put(
        `${process.env.GATSBY_SHARED_STRAPI_BASE_URL}/api/users/${userLoginState.user.id}`,
        data,
        { headers: { Authorization: `Bearer ${userLoginState.jwt}` } }
      );
      enqueueSnackbar("Profile updated.", {
        variant: "success",
        action: closeNotification,
      });
      // update notification details in newUser
      newUser.data.notification = notification.data;
      dispatch({
        type: UPDATE_USER_DETAILS,
        user: { ...newUser.data, avatar: userLoginState.avatar },
      });
      window.history.back();
      // navigate("/profile");
    } catch (err) {
      console.log("Unable to update user Avatar", err);
      enqueueSnackbar(err.response.data.error.message, {
        variant: "error",
        action: closeNotification,
      });
    }
  };
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <Box className="flex justify-evenly items-center mt-16">
      <div className="flex justify-evenly flex-col border-2 p-6 items-center gap-6 pd-24 border-primary">
        <Box className="flex flex-col gap-y-10">
          <TwoColorHeader
            title={
              editProfileState ? "Update|Profile" : "Complete Profile|Signup"
            }
            colorClassB="text-primary"
            className="font-bold text-4xl uppercase mb-2 drop-shadow-md"
          />
          <div className="flex justify-between items-center px-8">
            <Avatar
              src={img ? img : mmIcon}
              alt={userLoginState.user.artefyUserName}
              sx={{
                // background: "red",
                // border: "2px solid white",
                marginRight: { xs: "0rem", md: "1rem" },
                height: "100px",
                width: "100px",
              }}
            />
            {/* <button className="border border-primary px-3 py-2 flex justify-center items-center gap-x-2 text-primary">
              Upload Image
              <ArrowForwardIcon style={{ fontSize: 15 }} />
            </button> */}
            <label className="border border-primary px-3 py-2 cursor-pointer">
              <span className="flex justify-center items-center gap-x-2 text-primary">
                Upload Image
                <ArrowForwardIcon style={{ fontSize: 15, marginTop: "4px" }} />
              </span>
              <input type="file" className="hidden" onChange={handleImage} />
            </label>
          </div>
          <div className="flex flex-col gap-y-4">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <AccountCircleIcon
                  className="h-5 w-5 text-gray"
                  aria-hidden="true"
                />
              </div>
              <input
                ref={userNameInput}
                className="bg-transparent border border-gray pl-10 py-3 w-full"
                type="text"
                placeholder="Username"
                defaultValue={userLoginState?.user?.artefyUserName}
              />
            </div>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MailOutlineIcon
                  className="h-5 w-5 text-gray"
                  aria-hidden="true"
                />
              </div>
              <input
                ref={emailInput}
                className="bg-transparent border border-gray pl-10 py-3 w-full"
                type="email"
                placeholder="Email Address"
                defaultValue={
                  userLoginState.user?.email?.indexOf(
                    appConstants.default_dummay_email
                  ) !== -1
                    ? ""
                    : userLoginState.user.email
                }
              />
            </div>
            {
              <div className="flex flex-col gap-y-4">
                <div className="flex gap-x-2">
                  <input
                    ref={send_on_latest_blog_articles}
                    className="p-2 bg-black"
                    type="checkbox"
                    name="send_on_latest_blog_articles"
                    id="send_on_latest_blog_articles"
                    defaultChecked={
                      userLoginState.user.notification
                        ? userLoginState.user.notification.latest_blog_articles
                        : true
                    }
                  />
                  <label for="send_on_latest_blog_articles">
                    Send me an updates on latest blog articles
                  </label>
                </div>
                <div className="flex gap-x-2">
                  <input
                    ref={send_on_new_drops}
                    type="checkbox"
                    name="send_on_new_drops"
                    id="send_on_new_drops"
                    defaultChecked={
                      userLoginState.user.notification
                        ? userLoginState.user.notification.new_drops
                        : true
                    }
                  />
                  <label for="send_on_new_drops">Notify me of new drops</label>
                </div>
                <div className="flex gap-x-2">
                  <input
                    ref={send_on_general_marketing_emails}
                    type="checkbox"
                    name="marketing"
                    id="marketing"
                    defaultChecked={
                      userLoginState.user.notification
                        ? userLoginState.user.notification
                            .general_marketing_emails
                        : true
                    }
                  />
                  <label for="marketing">
                    Subscribe to general marketing emails
                  </label>
                </div>
              </div>
            }
            <button
              className="bg-primary flex justify-center items-center py-3 mt-2"
              onClick={updateProfile}
            >
              {editProfileState ? "Update Profile" : "Complete Signup"}
              <ArrowForwardIcon
                style={{ fontSize: 15, marginLeft: "10px", marginTop: "5px" }}
              />
            </button>
          </div>
        </Box>
      </div>
    </Box>
  );
};
export default UserProfile;
